<template>
  <v-form ref="cardForm" class="cardForm">
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-textarea
          rows="2"
          dense
          outlined
          v-model="reason"
          :rules="rules.emptyRules"
          label="Λόγος ακύρωσης"
          :disabled="isDisabled"
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row style="margin: 0px" v-if="!isDisabled">
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="$emit('closeForm')"
        class="m-r-10 font-weight-bold"
        style="text-transform: initial"
      >
        Άκυρο
      </v-btn>
      <v-btn
        @click="saveForm"
        color="orange"
        style="text-transform: initial; color: white"
      >
        Αποθήκευση
      </v-btn>
    </v-row>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    voucherData: {
      type: Object,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      reason: null,
      rules: {
        emptyRules: [(v) => !!v || "Το πεδίο είναι υποχρεωτικό"],
      },
    };
  },
  methods: {
    ...mapActions({
      cancelVoucher: "livetracking/livetracking/cancelVoucher",
    }),
    saveForm() {
      const isValid = this.$refs.cardForm.validate();
      if (isValid) {
        const payload = {
          vm: this,
          requestBody: {
            reason: this.reason,
            voucherId: this.voucherData._id,
          },
        };
        this.cancelVoucher(payload);
      }
    },
  },
  computed: {},
  mounted() {
    this.comment = this.voucherData.trackingActions.commentForRider;
  },
};
</script>

<style lang="scss">
.v-list-item__content {
  text-align: left;
}
</style>
